import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { Auth } from 'aws-amplify';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  async handleAuthentication() {
    const accessToken = this.getAccessToken();

    try {

      if (this.isValidToken(accessToken)) {
        this.setSession(accessToken);
      } else {

        // Get current (refreshed) session
        const currentSession = await Auth.currentSession();
        const jwtToken = currentSession.accessToken.jwtToken;

        if (this.isValidToken(jwtToken)) {
          this.setSession(jwtToken);
        } else {
          this.setSession(null);
        }
      }

    } catch (e) {
      console.log('error authService::handleAuthentication', e);
      this.setSession(null);
    }
  }

  isAuthenticated = () => !!this.getAccessToken()

  loginWithUsernameAndPassword = (username, password) => new Promise(async (resolve, reject) => {
    try {

      const cognitoUser = await Auth.signIn(username, password);
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      this.setSession(cognitoUser.signInUserSession.accessToken.jwtToken);

      const me = await axios.get('/users/me', {
        headers: {
          'Authorization': `Bearer ${cognitoUser.signInUserSession.accessToken.jwtToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      resolve(me.data);
    } catch (err) {
      reject(err)
    }
  })

  loginWithToken = () => new Promise(async (resolve, reject) => {

    try {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.accessToken.jwtToken;

      const me = await axios.get('/users/me', {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      resolve(me.data);
    } catch (err) {
      reject(err);
    }
  })

  register = ({username, password, email, given_name, family_name, privacy_policy}) => new Promise(async (resolve, reject) => {
    try {

      const cognitoUser = await Auth.signUp({
        username,
        password,
        attributes: {
            email,
            // other custom attributes 
            given_name,
            family_name,
            // 'custom:privacy_policy': privacy_policy
        }
      });

      const user = {
        username: cognitoUser.user.username,
        userConfirmed: cognitoUser.userConfirmed
      }

      resolve(user);
    } catch (error) {
        console.log('error signing up:', error);
        reject(error);
    }
  })

  loginWithRegistrationCode = (username, password, code) => new Promise(async (resolve, reject) => {

    // Finish registration, and confirm their signup
    try {
      await Auth.confirmSignUp(username, code);
    } catch (err) {
      console.log(err);

      // Something could have happened and they are trying to double confirm
      // Dont reject those errors since they already confirmed registration
      if (err.message !== 'User cannot be confirmed. Current status is CONFIRMED') {
        reject(err)
      }
    }

    // Try logging them in
    try {
      const user = await this.loginWithUsernameAndPassword(username, password);
      resolve(user);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  })

  forgotPassword = (username) => new Promise(async (resolve, reject) => {
    try {
      const res = await Auth.forgotPassword(username)
      resolve(res)
    } catch (err) {
      console.log(err);
      reject(err)
    }
  })

  loginWithForgotPasswordCode = (username, password, code) => new Promise(async (resolve, reject) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
    } catch (err) {
      console.log(err)
      reject(err)
    }

    // Try logging them in
    try {
      const user = await this.loginWithUsernameAndPassword(username, password);
      resolve(user);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  })

  logout = async () => {
    // eslint-disable-next-line
    const signout = await Auth.signOut();
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  // This will refresh session even with valid tokens.
  // This could be helpful for long running processes ie. video upload
  refreshSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const { accessToken } = session;

        this.setSession(accessToken.jwtToken);
      });
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

}

const authService = new AuthService();

export default authService;
