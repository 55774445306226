/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    maxHeight: 68
  },
}));

function NavBar() {
  const classes = useStyles();

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          // p={2}
          display="flex"
          justifyContent="center"
        >
          <RouterLink to="/">
            <Logo className={classes.logo} />
          </RouterLink>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
}

export default NavBar;
