/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c8882214-c357-4f6b-a175-bef15f524d4b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_X0ZIvzksX",
    "aws_user_pools_web_client_id": "40li4okp444v04oaljj74mgmfv",
    "oauth": {},
    "aws_mobile_analytics_app_id": "5ef32b84c8444dfab5123f16e8824002",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "workforce",
            "endpoint": "https://2xdl3obrz1.execute-api.us-east-1.amazonaws.com/workprod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
