import axios from 'src/utils/axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithUsernameAndPassword(username, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function loginWithRegistrationCode(username, password, code) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithRegistrationCode(username, password, code);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function forgotPassword(username) {
  return async () => {
    try {
      return await authService.forgotPassword(username);
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithForgotPasswordCode(username, password, code) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithForgotPasswordCode(username, password, code);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register({username, password, email, given_name, family_name, privacy_policy}) {
  return async () => {
    try {
      return await authService.register({username, password, email, given_name, family_name, privacy_policy});
    } catch (error) {
      throw error;
    }
  };
}

// Update account profile
export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
}

// Update user profile
export function updateUserProfile({userId, update}) {
  const request = axios.patch(`/users/${userId}`, { ...update });

  return (dispatch) => {
    request.then((response) => dispatch({
        type: UPDATE_PROFILE,
        payload: {user: response.data}
      })
    );
  };
}