/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import SurveyLayout from 'src/layouts/SurveyLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/thank-you',
    component: lazy(() =>  import('src/views/pages/ThankYouView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/new-password',
    component: lazy(() => import('src/views/auth/LoginForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() => import('src/views/auth/ConfirmationView'))
  },
  {
    path: '/accounts',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/accounts/:accountId/invite/:code',
        component: lazy(() => import('src/views/pages/AcceptAccountInviteView'))
      }
    ]
  },
  {
    exact: true,
    path: '/app/management/invoices/:invoiceId',
    component: lazy(() => import('src/views/management/InvoiceDetailsView'))
  },

  {
    path: '/surveys',
    guard: AuthGuard,
    layout: SurveyLayout,
    routes: [
      {
        exact: true,
        path: '/surveys/:code',
        component: lazy(() => import('src/views/pages/SurveyView'))
      }
    ]
  },
  {
    path: '/teams',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/teams/:teamId/invite/:code',
        component: lazy(() => import('src/views/pages/AcceptTeamInviteView'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      // {
      //   exact: true,
      //   path: '/app/account',
      //   component: lazy(() => import('src/views/pages/AccountView'))
      // },
      {
        exact: true,
        path: '/app/profile',
        component: lazy(() => import('src/views/pages/ProfileView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        // component: lazy(() => import('src/views/reports/DashboardAccountManagerView'))
        component: lazy(() => import('src/views/pages/ComingSoonView'))
      },
      {
        exact: true,
        path: '/app/reports/surveys/:surveyId/manager',
        component: lazy(() => import('src/views/reports/DashboardAccountManagerView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('src/views/management/UserListView'))
      },
      {
        exact: true,
        path: '/app/management/users/:userId',
        component: lazy(() => import('src/views/management/UserDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/users/:userId/edit',
        component: lazy(() => import('src/views/management/UserEditView'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/partners',
        component: lazy(() => import('src/views/management/PartnerListView'))
      },
      {
        exact: true,
        path: '/app/management/accounts',
        component: lazy(() => import('src/views/management/AccountListView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/create',
        component: lazy(() => import('src/views/management/AccountCreateView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId',
        component: lazy(() => import('src/views/management/AccountDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId/edit',
        component: lazy(() => import('src/views/management/AccountEditView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId/surveys',
        component: lazy(() => import('src/views/management/AccountSurveyListView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId/surveys/create',
        component: lazy(() => import('src/views/management/AccountSurveyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/choices/:choiceId/edit',
        component: lazy(() => import('src/views/management/ChoiceEditView'))
      },
      {
        exact: true,
        path: '/app/management/teams',
        component: lazy(() => import('src/views/management/TeamListView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId/teams',
        component: lazy(() => import('src/views/management/AccountTeamListView'))
      },
      {
        exact: true,
        path: '/app/management/accounts/:accountId/teams/create',
        component: lazy(() => import('src/views/management/TeamCreateView'))
      },
      {
        exact: true,
        path: '/app/management/teams/:teamId',
        component: lazy(() => import('src/views/management/TeamDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/teams/:teamId/edit',
        component: lazy(() => import('src/views/management/TeamEditView'))
      },
      {
        exact: true,
        path: '/app/management/surveys',
        component: lazy(() => import('src/views/management/SurveyListView'))
      },
      {
        exact: true,
        path: '/app/management/surveys/:surveyId',
        component: lazy(() => import('src/views/management/SurveyDetailsView'))
      },
      // app/management/surveys/2
      {
        exact: true,
        path: '/app/management/surveys/:surveyId/copy',
        component: lazy(() => import('src/views/management/SurveyCopyView'))
      },
      {
        exact: true,
        path: '/app/management/surveys/:surveyId/config',
        component: lazy(() => import('src/views/management/SurveyConfigView'))
      },
      {
        exact: true,
        path: '/app/management/surveys/:surveyId/edit',
        component: lazy(() => import('src/views/management/SurveyEditView'))
      },
      {
        exact: true,
        path: '/app/management/questions/:questionId/edit',
        component: lazy(() => import('src/views/management/QuestionEditView'))
      },
      {
        exact: true,
        path: '/app/management/questiongroups/:questionGroupId/edit',
        component: lazy(() => import('src/views/management/QuestionGroupEditView'))
      },
      {
        exact: true,
        path: '/app/management/surveys/:surveyId/results',
        component: lazy(() => import('src/views/management/SurveyResultsReportView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
