import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use((request) => {
  if ('patch' === request.method) {
    request.headers['Content-Type'] = 'application/merge-patch+json'
  }
  return request;
})

export default instance;
