/**
 * role_hierarchy
 * ROLE_MANAGER:     [ROLE_USER, ROLE_SUPPORT]
 * ROLE_DEV:         ROLE_MANAGER
 * ROLE_ADMIN:       ROLE_MANAGER
 * ROLE_SUPER_ADMIN: ROLE_ADMIN
 */
export const userHierarchy = [
  {role: 'ROLE_MANAGER', sub: ['ROLE_USER', 'ROLE_SUPPORT']},
  {role: 'ROLE_DEV', sub: ['ROLE_MANAGER']},
  {role: 'ROLE_ADMIN', sub: ['ROLE_MANAGER']},
  {role: 'ROLE_SUPER_ADMIN', sub: ['ROLE_ADMIN']}
];

/**
 * ACCOUNT_SURVEY: read - survey item results, team invites
 * ACCOUNT_USER: read - survey, teams, customers, dashboards
 * ACCOUNT_MANAGER: create|read|update - users, survey, accounts, partners, dashboards
 * ACCOUNT_ADMIN: 
 * ACCOUNT_OWNER: super admin can not be deleted, unless they designate another ACCOUNT_OWNER
 */
export const accountHierarchy = [
  {role: 'ACCOUNT_USER', sub: ['ACCOUNT_SURVEY']},
  {role: 'ACCOUNT_MANAGER', sub: ['ACCOUNT_USER']},
  {role: 'ACCOUNT_ADMIN', sub: ['ACCOUNT_MANAGER']},
  {role: 'ACCOUNT_OWNER', sub: ['ACCOUNT_ADMIN']}
];

/**
 * TEAM_MEMBER: A member of the team
 * TEAM_MANAGER: invite, remove team members
 */
export const teamHierarchy = [
  {role: 'TEAM_MANAGER', sub: ['TEAM_MEMBER']}
];

/**
 * 
 * @param {string} type Type of hierarchy ie. user, account, team
 * @param {string} role The role of the hierarchy ie. ROLE_USER, ACCOUNT_USER, TEAM_MANAGER
 * @param {array} roles An array of roles the user|account|team user has
 */
export function isGrantedHierarchy(type, role, roles) {

  let hierarchy = userHierarchy;
  if ('account' === type) hierarchy = accountHierarchy;
  if ('team' === type) hierarchy = teamHierarchy;

  const allowed = buildAllowedRoles(role, hierarchy);
  const matches = roles.filter(e => allowed.indexOf(e) !== -1);

  return matches.length > 0;
}

export function buildAllowedRoles(role, roles) {
  let allowedRolesArr = [role];
	allowedRoles(role, roles, allowedRolesArr);
  return allowedRolesArr;
}

export function buildAllowedUserRoles(role) {
  let allowedRolesArr = [role];
  allowedRoles(role, userHierarchy, allowedRolesArr);
  return allowedRolesArr;
}

export function buildAllowedAccountRoles(role) {
  let allowedRolesArr = [role];
  allowedRoles(role, accountHierarchy, allowedRolesArr);
  return allowedRolesArr;
}

export function buildAllowedTeamRoles(role) {
  let allowedRolesArr = [role];
  allowedRoles(role, teamHierarchy, allowedRolesArr);
  return allowedRolesArr;
}

function allowedRoles(role, roles, allowedRolesArr) {
  roles.find((value) => {
    if (value.sub.includes(role)) {
      allowedRolesArr.push(value.role);
      allowedRoles(value.role, roles, allowedRolesArr);
    }
    return value;
  });
}

/**
 * Will go deeper into a voter type interface
 * @link https://github.com/symfony/symfony/blob/5.1/src/Symfony/Component/Security/Core/Authorization/Voter/RoleVoter.php
 * @link https://github.com/symfony/symfony/blob/5.1/src/Symfony/Component/Security/Core/Role/RoleHierarchy.php
 * 
 * decide
 * @link https://github.com/symfony/symfony/blob/5.1/src/Symfony/Component/Security/Core/Authorization/AccessDecisionManager.php
 * 
 * isGranted
 * @link https://github.com/symfony/symfony/blob/5.1/src/Symfony/Component/Security/Core/Authorization/AuthorizationChecker.php
 * @param {*} attributes 
 * @param {*} subject 
 */
export function isGranted(attributes, subject = null) {
  console.log('use isRoleGranted');
  return false;
}
