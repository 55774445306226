/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';

import {
  Folder as FolderIcon,
  Users as UsersIcon,
  Flag as FlagIcon,
  Tag as TagIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { isGrantedHierarchy } from 'src/services/securityService';

// eslint-disable-next-line no-unused-vars
const surveysRead = {
  title: 'Surveys',
  icon: FolderIcon,
  href: '/app/management/surveys'
};

const surveysWrite = {
  title: 'Surveys',
  icon: FolderIcon,
  href: '/app/management/surveys',
  items: [
    {
      title: 'List Surveys',
      href: '/app/management/surveys'
    }
  ]
};

const usersRead = {
  title: 'Users',
  icon: UsersIcon,
  href: '/app/management/users',
};

// eslint-disable-next-line no-unused-vars
const accountsRead = {
  title: 'Accounts',
  icon: TagIcon,
  href: '/app/management/accounts'
};

const accountsWrite = {
  title: 'Accounts',
  icon: TagIcon,
  href: '/app/management/accounts',
  items: [
    {
      title: 'Create Account',
      href: '/app/management/accounts/create'
    },
    {
      title: 'All Accounts',
      href: '/app/management/accounts'
    }
  ]
};

// eslint-disable-next-line no-unused-vars
const teamsRead = {
  title: 'Teams',
  icon: FlagIcon,
  href: '/app/management/teams',
  items: [
    {
      title: 'My Teams',
      href: '/app/management/teams'
    },
    {
      title: 'Team Invites',
      href: '/app/management/teams/invites'
    },
  ]
};

const teamsWrite = {
  title: 'Teams',
  icon: FlagIcon,
  href: '/app/management/teams',
  items: [
    {
      title: 'All Teams',
      href: '/app/management/teams'
    },
    {
      title: 'Team Invites',
      href: '/app/management/teams/invites'
    },
  ]
};

const navInternal = [
  {
    subheader: 'Internal Dashboard',
    items: [
      usersRead,
      surveysWrite,
      accountsWrite,
      teamsWrite
    ]
  }
];

const navPartner = [
  {
    // subheader: 'Partner Dashboard',
    subheader: '',
    items: [
      // usersRead,
      // surveysWrite,
      // accountsWrite,
      // teamsWrite
    ]
  }
];

const navAccountSurvey = [
  {
    // subheader: 'Dashboard',
    subheader: '',
    items: [
      // surveysRead,
      // accountsRead,
      // teamsRead
    ]
  }
];

const navAccountManager = [
  {
    // subheader: 'Manager Dashboard',
    subheader: '',
    items: [
      // surveysRead,
      // accountsRead,
      // teamsRead
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: { 
    maxHeight: 68
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const [navConfig, setNavConfig] = useState(navAccountSurvey);
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    let navSet = false;

    const { roles, account_users } = user;

    if (isGrantedHierarchy('user', 'ROLE_SUPPORT', roles)) {
      setNavConfig(navInternal);
      navSet = true;
    }

    // If not a support role see if they have any account Users
    if (false === navSet && account_users.length > 0) {
      const accountPermissions = [];

      account_users.forEach((accountUser) => {
        accountPermissions.push({type: accountUser.account.type, roles: accountUser.roles});
      });

      let bestNav = {};
      // If more than one account lets try to figure out the best nav to show 
      // a user based on if they are a customer or partner
      if (accountPermissions.length > 1) {
        accountPermissions.find((value) => {
          if (typeof bestNav.type === 'undefined') bestNav = value;
          if ('partner' === value.type) {
            bestNav = {
              type: 'partner',
              roles: [
                ...bestNav.roles,
                ...value.roles
              ]
            };
          } else if ('customer' === value.type && 'partner' !== bestNav.type) {
            bestNav = {
              type: 'customer',
              roles: [
                ...bestNav.roles,
                ...value.roles
              ]
            };
          }
          return bestNav;
        });
  
        if (bestNav.roles.length > 1) {
          bestNav.roles = bestNav.roles.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        }
      } else {
        // They only had 1 or less permissions
        bestNav = accountPermissions[0];
      }

      if ('partner' === bestNav.type && isGrantedHierarchy('account', 'ACCOUNT_USER', bestNav.roles)) {
        setNavConfig(navPartner);
        navSet = true;
      } else if ('customer' === bestNav.type && isGrantedHierarchy('account', 'ACCOUNT_MANAGER', bestNav.roles)) {
        setNavConfig(navAccountManager);
        navSet = true;
      }
    }

  }, [user]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/profile">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/profile"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {
                typeof user.first_name !== 'undefined' && user.first_name
              }
              {
                typeof user.last_name !== 'undefined' && ` ${user.last_name}`
              }
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        {/* <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
